import React from 'react';
import './App.css';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { About, Blog, Work } from './components';
import './theme.css';

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Work />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
