import React from "react";
import { Link } from "react-router-dom";
import { Footer, Navbar } from "..";

export function About() {
  return (
    <div>
      <Navbar activePage="about" />

      <div className="hero">
        <h1 className="primary-blue">About Me</h1>
        <h5>It's nice to meet you.</h5>
      </div>

      <div className="main">
        <div className="container-wide">
          <section className="section-container">
            <div className="photogroup-container">
              <div className="photogroup">
                <div className="photocard-container">
                  <img className="photocard-image" src="wedding.jpeg" />
                  <p className="photocard-caption">
                    04/2022 - Atlanta, GA
                  </p>
                </div>
              </div>
            </div>

            <div className="right-column-text">
              <p className="intro-no-border">
                I am a Frontend Software Engineer at{" "}
                <a href="https://techcrunch.com/2022/06/09/backbase-raises-its-first-funding-128m-at-a-2-6b-valuation-for-tools-that-help-banks-with-engagement/" target="_blank">
                  Backbase
                </a>{" "}
                in Atlanta. I'm also a '19 Georgia Tech alum with an MS
                Computer Science focused on Human-Computer Interaction, with an
                undergrad background in Electrical Engineering.
              </p>

              <p>
                My interest in Human-Computer Interaction stems from realizing
                that the factors engineers consider when evaluating a product
                (e.g. power consumption, efficiency) are vastly different from
                what users care about (e.g. usability, solving their problem).
                And that the most successful tech products are the ones where
                the first set of factors are aligned to support the second.
              </p>

              <p>
                I am interested in performing this alignment. I enjoy wearing
                many hats and solving any kind of interesting problem, whether
                technical or non-technical, towards making tech products that
                are both efficient and effective.
              </p>

              <a
                target="_blank"
                className="grey-button"
                href="BenjaminDuPreez_Resume.pdf"
              >
                Download Resume
              </a>
            </div>
          </section>

          <section className="section-container">
            <div className="photogroup-container">
              <div className="photogroup">
                <div className="photocard-container">
                  <img className="photocard-image" src="vinyl.gif" />
                  <p className="photocard-caption">12/2019 - Vinyl</p>
                  <span className="photocard-tooltip">
                    Vinyl visualizes the characteristics of your favourite
                    artists' music
                  </span>
                </div>

                <div className="photocard-container">
                  <img className="photocard-image" src="living-blocks.gif" />
                  <p className="photocard-caption">12/2019 - LivingBlocks</p>
                  <span className="photocard-tooltip">
                    A visual recommendation of where in Atlanta to live
                  </span>
                </div>

                <div className="photocard-container hide-on-mobile">
                  <img className="photocard-image" src="cfa.jpg" />
                  <p className="photocard-caption">
                    05/2019 - Chick-fil-A Innovation Center
                  </p>
                  <span className="photocard-tooltip">
                    Augmented Reality glasses order tracking prototype
                  </span>
                </div>
              </div>
            </div>
            <div className="right-column-text">
              <h4>🛠️ Work</h4>
              <p>
                During my time at Georgia Tech, I grabbed any possible
                opportunities to learn more about Human-Computer Interaction and
                be involved in building cool stuff: conducting user interviews,
                doing full-stack development for web and mobile apps, running
                evaluations, doing research, and pitching in startup
                competitions. Along the way, I also learned more about / worked
                on projects related to Machine Learning, Data Analytics &
                Visualization, Computer Vision, and Management.
              </p>
              <p>
                Examples include Pond, an app to help
                you meet new people in your communities, and{" "}
                <a href="https://kenziemurphy.github.io/vinyl/?">Vinyl</a>, a
                visual music comparison tool which received 3000 visitors in its
                first week online.{" "}
              </p>
              <p>
                I also spent a summer working in the Chick-fil-A Innovation
                Center, where we prototyped an app for Vuzix Blade AR glasses
                connected to an AWS IoT network, allowing restaurant team
                members to interact with live order data on a heads-up-display
              </p>

              <p>
                Since graduation, I have mostly been working as a frontend
                engineer at fintech unicorn Backbase. In this role, I work with
                North American banks and credit unions to align our white-label
                banking app with their goals. I customize our product, coach
                client developers (and occasionally partners like Deloitte),
                give demoes, and build custom libraries to close feature gaps. I
                currently lead web frontend development of the onboarding flow
                for <a target="_blank" href="https://www.betabank.com/">BetaBank</a>, a brand-new digital bank based in Chicago.
              </p>

              <Link className="grey-button" to="/">
                More Work
              </Link>
            </div>
          </section>

          <section className="section-container">
            <div className="photogroup-container">
              <div className="photogroup">
                <div className="photocard-container">
                  <img className="photocard-image" src="jtnp.jpeg" />
                  <p className="photocard-caption">
                    01/2022 - Joshua Tree National Park, CA
                  </p>
                  <span className="photocard-tooltip">
                    I took this photo just before sunrise, on Kodak Ektachrome slide film
                  </span>
                </div>

                <div className="photocard-container">
                  <img className="photocard-image" src="ski.jpg" />
                  <p className="photocard-caption">
                    12/2022 - Breckenridge, CO
                  </p>
                  <span className="photocard-tooltip">
                    Learning to ski for the first time
                  </span>
                </div>
              </div>
            </div>
            <div className="right-column-text">
              <h4>🎉 Fun</h4>
              <p>
                Although I am currently based in Atlanta, I grew up near Cape
                Town in sunny South Africa. Cape Town has beautiful mountains,
                beaches, and a mild Mediterranean climate similar to coastal
                California. This has left me with a love of anything outdoors.
              </p>
              <p>
                I try to travel as often as I can: since moving to the US I have
                especially enjoyed taking roadtrips, exploring National Parks,
                and hiking new mountains.
              </p>
              <p>
                My mom is a photographer, and the interest has rubbed off on
                me. While in grad school, I was even a TA for Georgia Tech's <a target="_blank" href="https://www.udacity.com/course/computational-photography--ud955">Computational Photography course</a>. Then in 2021 I went the opposite direction - into analog photography. Feel free to check out
                my film photography Instagram,{" "}
                <a target="_blank" href="https://www.instagram.com/bdp.films/">
                  bdp.films
                </a>{" "}
                , where I try to document the beauty I see in my everyday life
                in America.
              </p>
              <p>
                I enjoy learning new things and reading up on a diversity of
                topics. I am currently learning about city planning and the
                residential architecture of the United States.
                The same is true for my hobbies: I love "figuring out" new ones.
                Last summer I started playing golf (I'm not very good yet) and
                gardening on my balcony. I especially enjoyed growing different
                kinds of chili peppers. Next summer, I plan to cross two
                different cultivars to create my own.
              </p>
              <p>
                In 2023, among other things, I hope to visit Germany, hike the
                Dolomites in Italy, and learn more about generative AI and
                digital art.
              </p>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
}
