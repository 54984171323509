import React from "react";
interface ToggleButtonProps {
  tag: string;
  onClickCallback: any;
  isSelected?: boolean;
}

export function ToggleButton(props: ToggleButtonProps) {

    function onClick() {
        props.onClickCallback(props.tag, props.isSelected);
    }

  return (
    <button
      className={`filter-button ${
        props.isSelected ? "filter-button-selected" : ""
      }`}
      onClick={onClick}
    >
      {props.tag}
    </button>
  );
}
