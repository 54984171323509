import React from "react";
import { ButtonProps } from "../../types";

export interface CardProps {
  title: string;
  text: string;
  image: string;
  tags: string[];
  tech?: string[];
  buttons: ButtonProps[];
}

export const Card: React.FunctionComponent<CardProps> = (props) => {

  function buildHTML(text: string) {
    return {__html: text};
  }
  return (
    <div className="card">
      <img className="card-image" src={props.image} />
      <p className="card-title">{props.title}</p>
      <p className="card-text" dangerouslySetInnerHTML={buildHTML(props.text)}></p>
      {
        props.tech?.length ? (<p className="card-text-support">
          <strong>My Tools: </strong>
          <span>{ props.tech.join(', ') }</span>
        </p>) : ''
      }
      <div className="card-buttons">
        {props.buttons.map((button: ButtonProps) => {
          return (
            <a
              href={button.href}
              target="_blank"
              className="grey-button card-button"
          >{ button.title }</a>
          );
        })}
      </div>
    </div>
  );
};
