import React from "react";
import { Link } from "react-router-dom";

export function Navbar(props: { activePage: 'work' | 'about' | 'blog' }) {
  return (
    <nav>
      <Link id="title" to="/work">
        <span className="primary-blue">b</span>
        <span className="secondary-blue">dp</span>
      </Link>
      <ul>
        <li className={`${props.activePage === 'work' ? 'active-nav-item' : ''}`}>
          <Link to="/work">Work</Link>
        </li>
        <li className={`${props.activePage === 'about' ? 'active-nav-item' : ''}`}>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </nav>
  );
}
