import React from "react";
import { Card, Footer, Navbar } from "..";
import { CardProps } from "../Card/Card";

export function Blog() {
  return (
    <div>
      <Navbar activePage="blog" />

      <div id="hero" className="hero">
        <h1 className="primary-blue">Blog</h1>
        <h5>This is where I write the stuff down that I think about.</h5>
      </div>

      <div className="main">
        <div id="spinner"></div>
        <div className="container-wide">
          <div id="cards">
            {"No Articles" &&
              localArticles.map((article) => <Card key={article.title} {...article} />)}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

const localArticles: Array<CardProps & { local: boolean }> = [
  {
    title: "The Internet in 10 Years",
    image: "signpost.jpg",
    text: "Transcending the gap between the digital and the physical",
    buttons: [{ title: 'Read', href: "the-internet-in-10-years" }],
    local: true,
    tags: [],
  },
  {
    title: "Pond",
    image: "pond.jpg",
    text: "Meet new people: a case study",
    buttons: [{ title: 'Read', href: "pond" }],
    local: true,
    tags: [],
  },
  {
    title: "Vier Seisoene in Eendrag",
    image: "eendrag.jpg",
    text: "'n Laaste groet",
    buttons: [{ title: 'Read', href: "vier-seisoene-in-eendrag" }],
    local: true,
    tags: [],
  },
];
