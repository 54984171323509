import React from "react";
import { Card, CardProps, Footer, Navbar, ToggleButton } from "..";

interface WorkState {
  searchString: string;
  filteringTags: string[];
  filteringControlsOpen: boolean;
}

export class Work extends React.Component<any, WorkState> {
  constructor(props: any) {
    super(props);
    this.state = {
      searchString: "",
      filteringTags: [],
      filteringControlsOpen: true,
    };
    this.handleFilteringStringChange = this.handleFilteringStringChange.bind(
      this
    );
    this.handleFilteringControlsOpenChange = this.handleFilteringControlsOpenChange.bind(
      this
    );
    this.handleToggleButtonClick = this.handleToggleButtonClick.bind(this);
  }

  handleFilteringStringChange(e: any) {
    this.setState({
      searchString: e.target.value,
    });
  }

  handleFilteringControlsOpenChange(e: any) {
    this.setState((state, props) => {
      return { filteringControlsOpen: !state.filteringControlsOpen };
    });
  }

  handleToggleButtonClick(tag: string, isSelected: boolean) {
    if(tag === 'All') {
      this.setState({
        filteringTags: []
      })
    }
    else if (isSelected) {
      this.setState({
        filteringTags: this.state.filteringTags.filter(
          (currTag) => currTag !== tag
        ),
      });
    } else {
      this.setState({
        filteringTags: this.state.filteringTags.concat([tag]),
      });
    }
  }

  render() {
    return (
      <div>
        <Navbar activePage="work" />

        <div id="hero" className="hero">
          <h1 id="floating-header-1">
            I'm <span className="primary-blue">BENJAMIN</span>
            <span className="secondary-blue"> DU PREEZ</span>
          </h1>
          <h3 id="floating-header-2">
            Human-Computer Interaction, Data Visualization, AI
          </h3>
          <h3 id="floating-header-3">
            Software Engineer at Backbase, Georgia Tech MS CS '19
          </h3>
        </div>

        <div className="main">
          <div id="filtering-controls">
            <div
              id="hideable-controls"
              className={
                this.state.filteringControlsOpen ? "slidedown" : "slideup"
              }
            >
              <div id="search">
                <input
                  id="searchbar"
                  type=""
                  name=""
                  placeholder="Search projects..."
                  onChange={this.handleFilteringStringChange}
                />
              </div>
              <div id="tags">
                {projects
                  .reduce(
                    (arr, val) => {
                      let currTags = val["tags"];
                      return arr.concat(currTags);
                    },
                    ["All"]
                  )
                  .filter((value, index, self) => {
                    return self.indexOf(value) === index;
                  })
                  .map((tag) => {
                    if (tag === "All")
                      return (
                        <ToggleButton
                          tag={tag}
                          isSelected={this.state.filteringTags.length === 0}
                          onClickCallback={this.handleToggleButtonClick}
                        />
                      );
                    return (
                      <ToggleButton
                        tag={tag}
                        isSelected={this.state.filteringTags.includes(tag)}
                        onClickCallback={this.handleToggleButtonClick}
                      />
                    );
                  })}
              </div>
            </div>

            <button
              id="toggle-controls-button"
              className={`action-button ${
                this.state.filteringControlsOpen ? "arrow-up" : "arrow-down"
              }`}
              onClick={this.handleFilteringControlsOpenChange}
            >
              &#x25B2;
            </button>
          </div>
          <div id="results-status">
            <h5></h5>
          </div>

          <div className="container-wide">
            <div id="cards">
              {"No Projects" &&
                projects
                  .filter((project) => {
                    return (
                      project.title
                        .toLowerCase()
                        .includes(this.state.searchString.toLowerCase()) &&
                      (this.state.filteringTags.length === 0 || hasIntersection(project.tags, this.state.filteringTags))
                    );
                  })
                  .map((project) => <Card key={project.title} {...project} />)}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

/* utility function that computes whether two functions intersect
		(used to check if any of a project's tags match any of the selected tags) */
function hasIntersection(list1: string[], list2: string[]) {
  for (let item of list1) {
    if (list2.includes(item)) {
      return true;
    }
  }

  return false;
}

const projects: CardProps[] = [
  {
    title: "💡 Supersee",
    text:
      "Supersee is a visual index of human history. An iteractive timeline of the overlapping lives of 45,000+ key historical figures, with AI-powered semantic search",
    image: "supersee.gif",
    tags: ["Information Visualization", "Web App", "Machine Learning"],
    buttons: [{ title: "Live App", href: "https://www.supersee.xyz/" }],
    tech: ['React', 'D3.js', 'Python', 'OpenAI API', 'LangChain']
  },
  {
    title: "🎞️ bdp.films",
    text:
      "I like to take film photographs of things I encounter on my travels and during my everyday daily life in America. Check out my Instagram page where I post them.",
    image: "bdpfilms.jpg",
    tags: ["Photography"],
    buttons: [{ title: "Instagram", href: "https://www.instagram.com/bdp.films/" }],
    tech: ['Nikon F100', 'Olympus Stylus 80', 'Photographic film', 'Adobe Lightroom']
  },
  {
    title: "🌌 Starrydate.cards",
    text:
      "Interactive collectible NFT cards visualizing the night sky at key moments in history. The thumbnail is <a target='_blank' href='https://www.britannica.com/story/the-solar-eclipse-that-made-albert-einstein-a-science-celebrity#:~:text=The%20eclipse%20presented%20a%20rare,created%20by%20the%20body's%20mass.>\"Einstein's Eclipse\"</a> of 1919 as seen at it's maximum. I calculated historical star and planet positions with Python and projected them into a styled visualization with D3.",
    image: "starrydate.gif",
    tags: [
      "Information Visualization",
      "NFT"
    ],
    buttons: [
      {
        title: "OpenSea",
        href: "https://opensea.io/collection/starrydatecards",
      },
    ],
    tech: ['JavaScript', 'D3.js', 'Python', 'skyfield']
  },
  {
    title: "📦 TenderBox",
    text:
      "TenderBox was a prototype procurement platform, built with <a target='_blank' href='https://www.linkedin.com/in/chrisjan-wust/'>Chrisjan Wüst</a> for <a target='_blank' href='https://youtu.be/A6B4WEQhO3M?t=73'>Capitec Bank's Life 2.0 Hackathon</a>. It would allow South African procurers to accept and manage bids online - something still mostly done on paper. ",
    image: "tenderbox.png",
    tags: ["Web App"],
    buttons: [{ title: "Live App", href: "https://www.tenderbox.net" }],
    tech: ['TypeScript', 'Angular']
  },
  {
    title: "🎉 QuarantinedParty",
    text:
      "Built amidst the COVID-19 lockdowns in early 2020 - QuarantinedParty instantly sets college students up in random small video hangouts with other verified students. Users can request a new match once every 5 minutes",
    image: "quarantined.gif",
    tags: ["Web App"],
    buttons: [],
    tech: ['TypeScript', 'Angular', 'Python', 'Flask', 'Node.js', 'Socket.IO']
  },
  {
    title: "🎵 Vinyl",
    text:
      "An interactive data visualization allowing users to compare songs of different artists based on audio features provided by Spotify. The visualization is made in D3.js, supported by a serverless API on AWS",
    image: "vinyl.gif",
    tags: ["Information Visualization", "UX Design", "Web App"],
    buttons: [
      { title: "Demo", href: "https://kenziemurphy.github.io/vinyl/" },
      { title: "Case Study", href: "https://taepras.com/work/vinyl" },
    ],
    tech: ['JavaScript', 'D3.js']
  },
  {
    title: "🏘️ LivingBlocks",
    text:
      "LivingBlocks analyzes big publicly available datasets (crime, restaurants etc.), driving time, and your personal preferences to create a visual recommendation of where in Atlanta you should look for a home",
    image: "living-blocks.gif",
    tags: [
      "Information Visualization",
      "UX Design",
      "Web App",
      "Data Analytics",
    ],
    buttons: [
      {
        title: "Demo",
        href: "https://benjamindupreez.github.io/living-blocks/",
      },
    ],
    tech: ['JavaScript', 'D3.js', 'Python']
  },
  {
    title: "🤝 Pond",
    text:
      "Pond strengthens social ties within physically proximate closed communities (e.g. Georgia Tech) by connecting users to have lunch or coffee with random available strangers within their community",
    image: "pond.jpg",
    tags: ["Mobile App", "UX Design"],
    buttons: [], // { title: "Case Study", href: "/pond" }
    tech: ['TypeScript', 'Angular']
  },
  {
    title: "👓 AR Order Tracking",
    text:
      "Prototyped a native Android app for Vuzix Blade AR glasses connected to an AWS IoT network, allowing restaurant team members at <a target='_blank' href='https://www.chick-fil-a.com/'>Chick-fil-A</a> to interact with live order data on a heads-up-display",
    image: "cfa.jpg",
    tags: ["IoT", "Augmented Reality"],
    buttons: [
    ],
    tech: ['Java', 'React', 'AWS']
  },
  {
    title: "🔍 Debugging Lens",
    text:
      "A transparent, moveable, resizeable object that reveals additional information on the Java Swing UI beneath it. Created as a graduate semester project for CS 6456: Principles of UI Software",
    image: "debugging-lens.jpg",
    tags: ["Research"],
    buttons: [
      { title: "Report", href: "UI_Software_Final_Report.pdf" },
      { title: "Code", href: "https://github.com/dominoweir/debugginglens" },
    ],
    tech: ['Java']
  },
  {
    title: "📻 Ubitune",
    text:
      "Ubitune uses background location tracking and the Spotify music preferences of all the people present in a public space (like a coffee shop) to automatically play music suited to everyone's tastes",
    image: "ubitune.jpg",
    tags: ["Mobile App"],
    buttons: [{ title: "Report", href: "Ubitune_Final_Report.pdf" }],
    tech: ['JavaScript', 'React Native']
  },
  {
    title: "🟩 Face Detection",
    text:
      "For <a href='https://omscs.gatech.edu/cs-6476-computer-vision' target='_blank'>CS 6476: Computer Vision</a> I completed various coding assignments on topics like local feature matching, scene recognition, and deep learning. Linked is my report for the one on face detection with sliding window",
    image: "2013_class_2.png",
    tags: ["Machine Learning"],
    buttons: [
      {
        title: "Report",
        href: "CV_Assignment5.pdf",
      },
    ],
    tech: ['Python', 'Numpy']
  },
  {
    title: "📈 Trading Strategy Learner",
    text:
      "For <a href='https://lucylabs.gatech.edu/ml4t/' target='_blank'>CS 6476: Machine Learning for Trading</a> I completed various assignments applying approaches like Decision Trees, Q-Learning, Ensemble Learning to financial data. Linked is my report for the final assignment: learning a trading strategy with a random forest",
    image: "StrategyLearnerVSBenchmarkInSample004.png",
    tags: ["Machine Learning"],
    buttons: [
      {
        title: "Report",
        href: "ML4T_report.pdf",
      },
    ],
    tech: ['Python', 'Numpy', 'Pandas']
  },
  {
    title: "🐟 Abalobi",
    text:
      "A traceable marketplace connecting small-scale South African fishers directly to high end restaurants, allowing consumers to track the story behind their fish back to the person who caught it. I worked as full-stack engineer as we were featured on national TV and increased signups 14x",
    image: "abalobi.jpeg",
    tags: ["Mobile App", "Web App"],
    buttons: [
      { title: 'Website', href: 'https://shop.fishwithastory.org/?gclid=CjwKCAiA2rOeBhAsEiwA2Pl7Q09pwyjylcbtpQV4q3CzcvxRHQarNa0hJXLfOt9bEUXgGuIGSlRm0hoCXCEQAvD_BwE'}
    ],
    tech: ['TypeScript', 'Angular', 'Node.js']
  },
  {
    title: "📍 Signpost",
    text:
      "Signpost was an experimental social network designed to enhance the interaction of people with the real world around them; based on the planting of interactive ephemeral markers called 'signposts'",
    image: "signpost.jpg",
    tags: ["Mobile App"],
    buttons: [
      {
        title: "Video",
        href:
          "https://drive.google.com/file/d/1NZ2nlTK-yX8bi50_kI9WkT9O2SBURwjU/view",
      },
    ],
    tech: ['Angular', 'Node.js']
  },
  {
    title: "💰 Visualizing Billionaires",
    text:
      "An interactive data visualization of the world's 1000 richest billionaires as listed by Forbes in 2014. The visualization was made with D3.js.",
    image: "billionaires.gif",
    tags: ["Information Visualization", "Web App"],
    buttons: [
      {
        title: "Demo",
        href: "https://benjamindupreez.github.io/visualizing-billionaires/",
      },
    ],
    tech: ['JavaScript', 'D3.js']
  },
  {
    title: "📡 Undergraduate Capstone",
    text:
      "This semester-long project mixed digital signal processing and forward error correction concepts by investigating the construction of binary linear block codes with FIR and IIR filters",
    image: "skripsie.jpg",
    tags: ["Research"],
    buttons: [
      {
        title: "Report",
        href: "18486460_DuPreez_BM_skripsie2017.pdf",
      },
      {
        title: "Code",
        href: "https://github.com/benjamindupreez/Error-Correction-and-DSP",
      },
    ],
    tech: ['MATLAB', 'LaTeX']
  },
  {
    title: "📰 Vires Unitatis",
    text:
      "In my senior year of undergrad I was the editor of Vires Unitatis, the 56 year old student newspaper of Eendrag Men's Residence. I also created infographics.",
    image: "vires.jpg",
    tags: ["Journalism"],
    buttons: [],
    tech: ['Adobe InDesign']
  },
  {
    title: "🔌 Power Supply",
    text:
      "Designed, built, and tested a standalone linear DC power supply, with an adjustable output voltage and current limit for an undergrad analog electronics design class. The output voltage and current limit are controllable either via analog knobs or digitally with PC software via an Arduino",
    image: "design.jpg",
    tags: ["Electronics"],
    buttons: [
      {
        title: "Report",
        href: "18486460_Report.pdf",
      },
    ],
    tech: ['Soldering Iron', 'SPICE', 'Python', 'Arduino']
  },
  {
    title: "🪩 Light 'Em Up",
    text:
      "This native Android DJ-crowd interaction app allows the crowd to show what genre of music they want to hear next. Created for Chicago-based DJ pair Light 'Em Up",
    image: "light-em-up.jpg",
    tags: ["Mobile App"],
    buttons: [],
    tech: ['Java']
  },
  {
    title: "👾 Galactic Gunners",
    text:
      "My own version of Space Invaders built as a class project for an Intro to Object-Oriented Programming in Java class",
    image: "invaders.jpg",
    tags: ["Game"],
    buttons: [
      {
        title: "Code",
        href: "https://github.com/benjamindupreez/Galactic-Gunners",
      },
    ],
    tech: ['Java']
  },
  {
    title: "🎣 Fishnotebook",
    text:
      "Built for my own use back in 2011 while I was still in high school - a native Android app allowing fishermen to log the specifics of their catches (e.g. bait, location etc) and generate statistics based on that to better plan future outings",
    image: "fishnotebook.jpg",
    tags: ["Mobile App"],
    buttons: [],
    tech: ['Java']
  },
];
